import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { SearchData } from "./SearchData";
import { useSearchParams } from "react-router-dom";
import { toast } from "sonner";
import { Modal } from "../../components/Modal";
import { fetchUser } from "../../redux/actions/auth";
import {
  handlePortalSession,
  handleEndFreeTrial,
} from "../../redux/actions/paymentActions";
import { creditsData } from "../../redux/actions/paymentActions";
import { AiOutlineSearch } from "react-icons/ai";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import _debounce from "lodash/debounce";
import { useDispatch } from "react-redux";
import ConfirmationModal from "../../components/ConfirmationModal";
import "../../assets/styles/style.css";

export const Dashboard = ({ fetchUser, user }) => {
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const [loading, setLoading] = useState(false);
  const [searchInput, setSearchInput] = useState("");
  const [tableData, setTableData] = useState([]);
  const [upgradeModal, setUpgradeModal] = useState(false);
  const [freeTrialModal, setFreeTrialModal] = useState(false);

  const currentPlan = useSelector((state) => state.stripe.currentPlan);

  const dispatch = useDispatch();
  const inventoryData = useSelector((state) => state.ideas.generatedData);

  useEffect(() => {
    const debouncedSearch = _debounce(() => {
      if (inventoryData.length === 0) {
        setTableData([]);
      } else {
        const filteredData = inventoryData.filter((item) =>
          item.searchTerm.toLowerCase().includes(searchInput.toLowerCase())
        );
        setTableData(filteredData);
      }
    }, 300);

    debouncedSearch();
    return () => debouncedSearch.cancel();
  }, [searchInput, inventoryData]);

  useEffect(() => {
    if (searchParams.get("payment_status")) {
      if (searchParams.get("payment_status") === "success") {
        toast.success("Payment successful");
      } else if (searchParams.get("payment_status") === "failed") {
        toast.error("Payment Canceled!!");
      }
      searchParams.delete("payment_status");
      if (searchParams.get("session_id")) {
        searchParams.delete("session_id");
      }
      setSearchParams(searchParams);
    }

    document.title = "Idea research - YTA Studio";
  }, []);

  const upgradeModalHandler = async () => {
    try {
      setLoading(true);
      await handleEndFreeTrial();
      var result = await fetchUser();
      dispatch(creditsData());
      // window.location.reload();
      // Introduce a delay of 10 seconds
      await new Promise((resolve) => setTimeout(resolve, 5000));
      setFreeTrialModal(false);
      toast.success(
        "Upgrade Successful, It might take a few minutes to reflect in your account."
      );
    } catch (error) {
      console.log(error, "Error in get settings");
    } finally {
      setLoading(false);
      setFreeTrialModal(false);
    }
  };

  const columns = ["Name", "Date", "Results", " "];

  const isMobileScreen = window.innerWidth < 768;
  return (
    <div className="relative">
      {isMobileScreen ? (
        <div className="px-5 py-3.5 font-Inter">
          <div className="flex justify-between">
            <div className="title">Idea Research</div>
            <div>
              <button
                className={`bg-primary text-white rounded-xl outline-none py-1 px-3 flex items-center gap-x-2`}
                onClick={() => {
                  if (!user) {
                    return;
                  }
                  if (user?.plan_id === process.env.REACT_APP_BASE_PLAN_ID) {
                    toast.error("Please upgrade your plan to use this feature");
                    return;
                  }

                  navigate("/dashboard/start-search");
                }}
              >
                <p style={isMobileScreen ? { fontSize: "15px" } : {}}>
                  Start Search
                </p>
              </button>
            </div>
          </div>
          {/* <hr className="bg-gray-200 h-[1.15px] mt-1" /> */}
          <div className={`flex justify-end mt-2`}>
            <input
              className="bg-[#F9FAFB] rounded-lg w-full placeholder-[#6B7280] outline-none py-1 px-3 border border-[#E5E7EB]"
              placeholder="Search"
              maxLength={200}
              onChange={(e) => setSearchInput(e.target.value)}
            />
          </div>
        </div>
      ) : (
        <>
          <div
            className={`px-5 py-3.5 flex w-full justify-between items-center font-Inter `}
          >
            <div className="flex items-center space-x-3 w-[60%]">
              <p className="title">Idea Research</p>
              <input
                className="bg-[#F9FAFB] rounded-full w-1/2 placeholder-[#6B7280] outline-none py-2 px-5 border border-[#E5E7EB]"
                placeholder="Search"
                maxLength={200}
                onChange={(e) => setSearchInput(e.target.value)}
              />
            </div>
            <button
              className="bg-primary text-white rounded-xl outline-none py-2 px-4 flex items-center gap-x-2 topBarBtn"
              onClick={() => {
                if (!user) {
                  return;
                }
                if (user?.plan_id === process.env.REACT_APP_BASE_PLAN_ID) {
                  toast.error("Please upgrade your plan to use this feature");
                  return;
                }

                navigate("/dashboard/start-search");
              }}
            >
              <AiOutlineSearch className="w-5 h-full" /> <p>Start Search</p>
            </button>
          </div>
          <hr className="bg-gray-200 h-[1.15px] mt-1" />
        </>
      )}
      <SearchData tableData={tableData} />

      {user &&
        !user.isAdmin &&
        (user?.plan_id === process.env.REACT_APP_BASE_PLAN_ID ||
          user?.freeTrialStatus !== "active") && (
          <div
            className={`absolute md:fixed top-0 min-h-screen bg-black bg-opacity-30 backdrop-blur-sm flex justify-center px-5  items-center w-full ${
              isMobileScreen ? "z-0" : "z-30"
            }`}
          >
            <Modal width={"w-1/4"} handleClose={() => {}}>
              <div className="text-center w-full">
                <svg
                  className="mx-auto"
                  width="56"
                  height="56"
                  viewBox="0 0 56 56"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <rect
                    x="4"
                    y="4"
                    width="48"
                    height="48"
                    rx="24"
                    fill="#FAD1D1"
                  />
                  <rect
                    x="4"
                    y="4"
                    width="48"
                    height="48"
                    rx="24"
                    stroke="#FDECEC"
                    stroke-width="8"
                  />
                  <path
                    d="M28 31V33M22 37H34C34.5304 37 35.0391 36.7893 35.4142 36.4142C35.7893 36.0391 36 35.5304 36 35V29C36 28.4696 35.7893 27.9609 35.4142 27.5858C35.0391 27.2107 34.5304 27 34 27H22C21.4696 27 20.9609 27.2107 20.5858 27.5858C20.2107 27.9609 20 28.4696 20 29V35C20 35.5304 20.2107 36.0391 20.5858 36.4142C20.9609 36.7893 21.4696 37 22 37ZM32 27V23C32 21.9391 31.5786 20.9217 30.8284 20.1716C30.0783 19.4214 29.0609 19 28 19C26.9391 19 25.9217 19.4214 25.1716 20.1716C24.4214 20.9217 24 21.9391 24 23V27H32Z"
                    stroke="#E14B45"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>

                <p className="text-lg text-slate-600 font-semibold text-Inter mt-3">
                  {currentPlan === "Standard"
                    ? "🚀 Upgrade to Pro 🚀"
                    : "End Free Trial"}
                </p>
                <p className="text-sm text-secondarySupport mt-1.5 text-Inter">
                  {
                    "Unlock full potential with Pro: More features, faster performance, priority support - upgrade now"
                  }
                </p>

                <div className="flex item-center justify-evenly gap-x-5 mt-6">
                  <button
                    onClick={() => {
                      if (currentPlan !== "Standard") {
                        console.log("user?.plan_id", user?.plan_id);
                        setFreeTrialModal(true);
                      } else {
                        console.log("handlePortalSession");
                        setUpgradeModal(true);
                      }
                      // navigate('/payment-options')
                    }}
                    className="px-4 py-2 w-full text-sm text-Inter font-semibold bg-primary text-white rounded-lg border-[1px] border-primary"
                  >
                    {loading ? (
                      <svg
                        role="status"
                        className="inline mr-3 w-4 h-4 text-white  animate-spin"
                        viewBox="0 0 100 101"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        {/* ... (your SVG path data) ... */}
                      </svg>
                    ) : currentPlan !== "Standard" ? (
                      "Skip Free Trial"
                    ) : (
                      "Upgrade"
                    )}
                  </button>
                </div>
              </div>
            </Modal>
          </div>
        )}
      {upgradeModal && (
        <ConfirmationModal
          heading="Upgrade Your Experience!"
          message="Elevate your experience with Pro: Access advanced capabilities including Idea Research and Youtube Audit. Enhance your platform utility today, it will cost you $99."
          onCancel={() => {
            setUpgradeModal(false);
          }}
          onConfirm={() => {
            handlePortalSession();
          }}
          loading={loading}
        />
      )}
      {freeTrialModal && (
        <ConfirmationModal
          heading="Upgrade Your Experience Instantly!"
          message="End your free trial now and seamlessly transition into a subscription. Elevate your experience and enjoy additional credits instantly. It will cost you $100."
          onCancel={() => {
            setFreeTrialModal(false);
          }}
          onConfirm={() => {
            upgradeModalHandler();
          }}
          loading={loading}
        />
      )}
    </div>
  );
};

const mapStateToProps = (state) => ({
  user: state.auth.user,
});

const mapDispatchToProps = { handlePortalSession, fetchUser };

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
